import React from 'react'
// import { useQuery } from 'urql'
import Button from '../../reusable_components/buttons/Button'
// import ContentSideBarContext from '../../../contexts/content_side_bar/ContentSideBarContext'
// import LoadingSpinner from '../../reusable_components/other/LoadingSpinner'

/*const getCourse = gql`
	query ($course_id: ID!) {
		course(id: $course_id) {
			courseCard
		}
	}
`*/

const BookDemoButton = ({ t, color }) => {
	/*const [result] = useQuery({
		query: getCourse,
		variables: { course_id: 1820 },
	})
	const { dispatch } = useContext(ContentSideBarContext)

	const { data, fetching, error } = result
	if (fetching || error) return <LoadingSpinner />
	const course = data.course.courseCard
	const bookDemo = event => {
		// The card is an a tag link so it can be indexed by Google, but we want the overlay to open instead of
		// the user being rerouted to the course page, so prevent the default of the a tag.
		event.preventDefault()
		dispatch({
			type: 'NEW_CONTENT',
			data: { content: course, contentType: 'COURSE' },
		})
	}*/
	return (
		<a href="https://calendly.com/ejbla/30min?month=2024-04" target="_blank">
			<Button color={color}>{t('common:buttons.bookDemo')}</Button>
		</a>
	)
	/*return (
		<a onClick={bookDemo} href={course?.url}>
			<Button color={color}>{t('common:buttons.bookDemo')}</Button>
		</a>
	)*/
}

export default BookDemoButton
